import { useEffect, useState } from 'react';
import SingleCard from './components/SingleCard';
import './App.css';
import Flavour from './aud/Flavour.wav';
import Right from './aud/Right.wav';
import Victory from './aud/Victory.wav';
import Wrong from './aud/Wrong.wav';

const cardstack = [
  {"src": "/img/reactory0001.png", matched: false},
  {"src": "/img/reactory0002.png", matched: false},
  {"src": "/img/reactory0003.png", matched: false},
  {"src": "/img/reactory0004.png", matched: false},
  {"src": "/img/reactory0005.png", matched: false},
  {"src": "/img/reactory0006.png", matched: false}
];

function App() {
  const [cards, setCards] = useState([]);
  const [turns, setTurns] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(false);

  function playFlavour() {
    new Audio(Flavour).play();
  }
  
  function playRight() {
    new Audio(Right).play();
  }
  
  function playVictory() {
    new Audio(Victory).play();
  }
  
  function playWrong() {
    new Audio(Wrong).play();
  }

  // Duplicate cards for shuffle
  const shuffleCards = () => {
    // Combine and shuffle the cardstack array randomly to create a new set of cards
    const shuffledCards = [...cardstack, ...cardstack].sort(() => Math.random() - 0.5).map((card) => ({ ...card, id: Math.random() }));
    setChoiceOne(null);
    setChoiceTwo(null);
    setCards(shuffledCards);
    setTurns(0);
  }

  // Handle choice
  const handleChoice = (card) => {
    // Check if the selected card is the same as the first choice
    if (card.id === choiceOne?.id) {
      return; // Do nothing if the selected card is the same as the first choice
    }
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card); // Set the second choice if the first choice exists, otherwise set the first choice
  }

  // Compare 2 selected cards
  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);

      if (choiceOne.src === choiceTwo.src) {
        setCards(prevCards => {
          // Update the cards array by marking the matched cards as "matched"
          const updatedCards = prevCards.map(card => {
            if (card.src === choiceOne.src) {
              return { ...card, matched: true }; // Mark the matched cards as matched
            }
            return card;
          });

          const remainingUnmatchedCards = updatedCards.filter(card => !card.matched);
          if (remainingUnmatchedCards.length > 0) {
            playRight(); // Play the sound effect for a correct match
          }
          return updatedCards;
        });

        resetTurn();
      } else {
        setTimeout(() => {
          resetTurn();
        }, 1000);
      }
    }
  }, [choiceOne, choiceTwo]);

  // Reset turn and play sound effect
  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns(prevTurns => prevTurns + 1);
    setDisabled(false);

    if (choiceOne.src !== choiceTwo.src) {
      playWrong(); // Play the sound effect for a wrong match
    }
  };

  // Check for victory condition
  useEffect(() => {
    const matchedCardsCount = cards.filter(card => card.matched).length;

    if (matchedCardsCount === cards.length) {
      playVictory(); // Play the sound effect for a victory when all cards are matched
    }
  }, [cards]);

  // Start a new game immediately
  useEffect(() => {
    shuffleCards();
  }, []);

  return (
    <div className="App">
      <h1 className="font-link">Reactory</h1>
      <button onClick={shuffleCards}>New Game</button>

      <div className="card-grid">
        {cards.map(card => (
          <SingleCard
            key={card.id}
            card={card}
            handleChoice={handleChoice}
            flipped={card === choiceOne || card === choiceTwo || card.matched}
            disabled={disabled}
          />
        ))}
      </div>
      <p className="font-link">Turns: {turns}</p>
    </div>
  );
}

export default App;
